.main {
  margin-top: 80px;
  justify-content: center;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
}

.contact {
  margin: auto;
  width: 50%;
}

.input {
  margin-top: 1%;
  margin-bottom: 4%;
  width: 60%;
}

.message {
  margin-top: 1%;
  margin-bottom: 4%;
  width: 100%;
  height: 200px;
  resize: none;
}

.error {
  color: red;
}