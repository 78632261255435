.main {
  margin-top: 80px;
  padding-top: 20px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.highlight {
  background-color: #ffcc00;
  font-weight: bold;
}

.test {
  margin: 25px 0 80px 0;
}

.image {
  width: 50%;
  box-shadow: 5px 10px 8px #888888;
}

.h1 {
  font-size: 60px;
}

.p {
  font-size: 30px;
}

