.header {
    position:sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    background-color: white;
}

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 80%;
    height: 80px;
    padding-left: 10%;
    padding-right: 10%;
}

.subcontainer {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left;
    align-items: left;
}

.item {
    flex-grow: 1;
    text-align: center;
}

.link {
    text-decoration: none;
    padding: 10px;
    color:black;
}

.link:hover {
    background-color: #ffcc00;
}

